const propiedades = []

var map = L.map('map').setView([20.665315530661413, -103.34564878294594], 10);
L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
}).addTo(map);

propiedades.push(L.marker([20.75409063619668, -103.41608720392561], {
    nombre: "San Ángel Industrial Park ",
    descripcion:"Dos bodegas construidas de <span class='negritas'>5,000m²</span>  y <span class='negritas'>2,826m²</span>"
}));

propiedades.push(L.marker([20.720432356384983, -103.49517530476768], {
    nombre: "Parque industrial Nogales",
    descripcion:"Mas de <span class='negritas'>50,000m²</span> en bodegas y <span class='negritas'>90,000m²</span> en urbanizacion"
}));

propiedades.push(L.marker([20.78572445352068, -103.4685865521074], {
    nombre: "Tesistan 3",
    descripcion:"Mas de <span class='negritas'>27,000m²</span> en bodegas y <span class='negritas'>50,000m²</span> en urbanizacion"
}));

propiedades.push(L.marker([20.76411654011373, -103.42416670008468], {
    nombre: "Av. Guadalajara",
    descripcion:"Bodega de <span class='negritas'>1,000m²</span>"
}));

propiedades.push(L.marker([20.737124400000027, -103.40006602895582], {
    nombre: "Optimex",
    descripcion:"Bodega de <span class='negritas'>2,000m²</span>"
}));
propiedades.push(L.marker([20.63804555968163, -103.32307705774024], {
    nombre: "Sajor",
    descripcion:"Bodega de <span class='negritas'>3,700m²</span>"
}));
propiedades.push(L.marker([20.649226937487775, -103.3341023270513], {
    nombre: "Gonzalez Gallo",
    descripcion:"Bodega de <span class='negritas'>5,700m²</span>"
}));
propiedades.push(L.marker([20.632171996466916, -103.32477584426195], {
    nombre: "Corman",
    descripcion:"Bodega de <span class='negritas'>8,000m²</span>"
}));
propiedades.push(L.marker([20.64472219999054, -103.33851569026967], {
    nombre: "R. Michel",
    descripcion:"Bodega de <span class='negritas'>2,510m²</span>"
}));
//propiedades.push(L.marker([20.675532851484427, -103.36712420501716], {
  //  nombre: "Calle Amapola",
    //descripcion:"Bodega de 1,700m²"
//}));
propiedades.push(L.marker([20.720251772174706, -103.4938234667636], {
    nombre: "West Park",
    descripcion:"5 Bodega con un total de <span class='negritas'>5,700m²</span>"
}));
//propiedades.push(L.marker([20.675532851484427, -103.36712420501716], {
  //  nombre: "San Ángel Leon",
    //descripcion:"Mas de 31,000m² en bodegas"
//}));
propiedades.push(L.marker([20.79296935861893, -103.45964866931263], {
    nombre: "Capithal Park Colotlan",
    descripcion:"Mas de <span class='negritas'>101,000m²</span> en bodegas y <span class='negritas'>320,000m²</span> en urbanizacion"
}));
propiedades.push(L.marker([20.73254013626775, -103.52832257125769], {
    nombre: "Grun Park",
    descripcion:"Bodega de <span class='negritas'>800m²</span>"
}));
propiedades.push(L.marker([20.758313235274677, -103.5344658577488], {
    nombre: "Nextipac",
    descripcion:"Bodega de <span class='negritas'>7,000m²</span> y mas de <span class='negritas'>100,000m²</span> de urbanizacion"
}));
//propiedades.push(L.marker([20.675532851484427, -103.36712420501716], {
  //  nombre: "Dr. Sanabia Periferico",
    //descripcion:"Bodega de 500m²"
//}));
//propiedades.push(L.marker([20.675532851484427, -103.36712420501716], {
  //  nombre: "Basuerero",
    //descripcion:"Bodega de 1,500m²"
//}));
propiedades.push(L.marker([20.620388989476613, -103.2088233174708], {
    nombre: "Elite",
    descripcion:"Bodega de <span class='negritas'>1,600m²</span>"
}));
propiedades.push(L.marker([20.63901242076409, -103.27530285588935], {
    nombre: "Funeria San Javier",
    descripcion:"Construccion de <span class='negritas'>10,000m²</span>"
}));
propiedades.push(L.marker([20.556030734239886, -103.36279925778481], {
    nombre: "Funeria San Javier",
    descripcion:"Construccion de <span class='negritas'>10,000m²</span>"
}));
propiedades.push(L.marker([20.63694439998623, -103.31706241728581], {
    nombre: "Funeria San Javier",
    descripcion:"Construccion de <span class='negritas'>10,000m²</span>"
}));

propiedades.forEach(elemento => {
    var datos = `<h2 class="tituloMarcador">${elemento.options.nombre}</h2><br><span class="descripcionMarcador">${elemento.options.descripcion} </span>`
    var myIcon = L.icon({
        iconUrl: 'build/img/iconos/marcador-de-posicion.webp',
        iconSize: [40, 40], // Tamaño del icono [ancho, alto]
        iconAnchor: [20, 40], // Punto de anclaje del icono (generalmente la mitad del ancho y la altura)
        popupAnchor: [0, -40] // Punto de anclaje del pop-up en relación al icono
    });
    elemento.setIcon(myIcon)
    elemento.bindPopup(datos)
    elemento.addTo(map)    
});







var map2 = L.map('map2').setView([20.709870638643427, -103.37824982698278], 15);
L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
}).addTo(map2);

var greenIcon = L.icon({
    iconUrl: 'build/img/pinMapa.webp',

    iconSize: [40, 40], // Tamaño del icono [ancho, alto]
    iconAnchor: [20, 40], // Punto de anclaje del icono (generalmente la mitad del ancho y la altura)
    popupAnchor: [0, -40]
});

L.marker([20.709870638643427, -103.37824982698278], {icon: greenIcon}).addTo(map2);